<template>
  <v-container fluid>
    <v-row class="py-0 insideTopBarWrap">
      <v-col
        cols="2"
        class="insideTopBackButton"
      >
        <v-btn
          color="dark"
          text
          small
          @click="redirectBack"
        >
          <v-icon> mdi-arrow-left </v-icon>
        </v-btn>
      </v-col>
      <v-col
        cols="2"
        class="insideTopFilter"
      >
        <div class="top-filters">
          <v-select
            v-model="options.vehicle_id"
            dense
            filled
            :loading="isVehicle"
            :items="vehicleList"
            item-value="id"
            item-text="vehicle_name"
            label="Select Vehicle"
            @change="onVehicleChange"
          />
        </div>
      </v-col>
    </v-row>
    <!-- Telematics Headers -->
    <telematice-header-info :options="options" />
    <!-- Telematics Headers -->

    <v-card class="tabsCard">
      <v-row class="mx-0 my-0">
        <v-col
          cols="12"
          class="px-0 py-0"
        >
          <div class="">
            <v-tabs
              v-model="tab"
              show-arrows
              class="customTab"
              @change="tabChanged(tab)"
            >
              <span
                class="d-flex justify-space-between"
                style="flex: 1"
              >
                <span class="d-flex">
                  <v-tab
                    v-for="tabItem in items"
                    :key="tabItem.tab"
                  >
                    {{ tabItem.tab }}
                  </v-tab>
                </span>
                <div
                  v-if="authUser.isAdmin && selectedTab == 'commands-tab'"
                  class="v-tab px-0"
                >
                  <send-command />
                </div>
              </span>

              <v-tab-item
                v-if="isPermission('vehicle-telematics-detail.first-tab')"
              >
                <overview-tab
                  name="overview-tab"
                  :options="options"
                  :selected-tab="selectedTab"
                />
              </v-tab-item>

              <v-tab-item
                v-if="isPermission('vehicle-telematics-detail.dashboard')"
              >
                <vehicle-dashboard-tab
                  name="dashboard-tab"
                  :option-filters="options"
                  :selected-tab="selectedTab"
                />
              </v-tab-item>

              <!--Tab 1 : Message tab  -->
              <v-tab-item>
                <messages-tab
                  name="messages-tab"
                  :option-filters="options"
                  :selected-tab="selectedTab"
                />
              </v-tab-item>

              <!--Tab 2 : Command tab  -->
              <v-tab-item>
                <commands-tab
                  name="commands-tab"
                  :option-filters="options"
                  :selected-tab="selectedTab"
                />
              </v-tab-item>

              <!--Tab 2 : Stream tab  -->
              <v-tab-item>
                <streams-listing
                  :show-headers="false"
                  :bread-crumbs="false"
                />
              </v-tab-item>

              <!--Tab 2 : Plugins tab  -->
              <v-tab-item>
                <plugins-listing
                  :show-headers="false"
                  :bread-crumbs="false"
                />
              </v-tab-item>

              <!--Tab 2 : Plugins tab  -->
            </v-tabs>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {
    telematiceHeaderInfo: () =>
      import("@/components/sections/telematic-screen/Header-Info.vue"),
    OverviewTab: () => import("../tabs/OverviewTab.vue"),
    VehicleDashboardTab: () => import("../tabs/VehicleDashboardTab.vue"),
    MessagesTab: () => import("../tabs/MessagesTab1.vue"),
    CommandsTab: () => import("../tabs/CommandsTab.vue"),
    StreamsListing: () => import("@/views/modules/flespi/Streams.vue"),
    PluginsListing: () => import("@/views/modules/flespi/Plugins.vue"),
    SendCommand: () => import("../utils/SendCommand.vue"),
  },

  data() {
    return {
      tab: 0,
      selectedTab: null,
      options: {
        vehicle_id: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isVehicle: "vehicles/getIsLoadingVehicles",
      vehicleList: "vehicles/getVehiclesList",
    }),
    items() {
      let tab = [
        ...(this.$admin.hasAccessTo("vehicle-telematics-detail.first-tab")
          ? [{ tab: this.$t("overview"), content: "overview-tab" }]
          : []),

        ...(this.$admin.hasAccessTo("vehicle-telematics-detail.dashboard")
          ? [{ tab: this.$t("dashboard"), content: "dashboard-tab" }]
          : []),

        { tab: this.$t("messages"), content: "messages-tab" },

        ...(this.authUser.isAdmin
          ? [{ tab: this.$t("commands"), content: "commands-tab" }]
          : []),
        ...(this.authUser.isAdmin
          ? [{ tab: this.$t("streams"), content: "stream-tab" }]
          : []),
        ...(this.authUser.isAdmin
          ? [{ tab: this.$t("plugins"), content: "plugin-tab" }]
          : []),
      ];

      return tab;
    },
  },

  async mounted() {
    this.tabChanged(this.tab);
    // Get All Vehicles
    await this.getVehicles();
    // Set Selected Vehicles from Query Params
    this.options.vehicle_id = parseInt(this.$route.params.vehicle_id);
    this.onVehicleChange(this.options.vehicle_id);
  },

  methods: {
    isPermission(val) {
      return this.$admin.can(val);
    },

    tabChanged(val) {
      const t = this.items[val];
      this.selectedTab = t.content;
    },
    async getVehicles() {
      const params = {
        // tenant_id: this.authUser.isAdmin
        //   ? this.$route.query?.tenant_id
        //   : this.authUser.tenant_id,
        tenant_id: this.$route.query?.tenant_id,
      };
      await this.$store.dispatch("vehicles/vehicleDropDown", params);
    },
    onVehicleChange(val) {
      const r = this.vehicleList.find((r) => r.id == val);
      this.options.imei = r.iot_device_imei;
    },

    redirectBack() {
      this.$store.commit("telematicDetails/CLEAR_TELEMATICDETAILS");
      return this.$router.push({
        name: "vehicle_telematics",
        query: this.$route.query,
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.flex-boxes
  display: flex
  justify-content: space-around
  flex-wrap: wrap
  .box
    flex-basis: 15%
    margin: 0.5rem 0

    .v-card__title
      color: gray
      font-size: 1rem
      text-wrap : nowrap
    .v-card__text
      text-align : center

  .info-panel
    flex-basis: 25%
    margin: 0.5rem 0
    text-wrap: nowrap
    .v-card__text
      table
        width:100%
        font-size: 14px
      tr td:first-child
        color: gray
      tr td:last-child
        font-weight: 600

.collapse-btn
  display: flex
  justify-content: space-between
  align-items: center
</style>
